interface pageActionsModel {
  PageName: string,
  ActionNames?: { [key: string]: string }
}
export const pageActions: pageActionsModel[] = [
  {
    PageName: 'Examiner Hub',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'Examiner Resource Planning',
    ActionNames: {
      View: 'View',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'Spk Deployment',
    ActionNames: {
      View: 'View',
      AssignView: 'Assign-view',
      AssignEdit: 'Assign-edit',
      Sync: 'Sync',
      ExportExaminerArrangement: 'Export Examiner Arrangement',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'User Management',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'Role Management',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      Delete: 'Delete'
    }
  },
  {
    PageName: 'Examiner Profile',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'Certification Records',
    ActionNames: {
      View: 'View',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  }
]
